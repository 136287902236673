import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import URLs from 'src/utils/urls';


const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        social { twitter }
      }
    }
  }
`;


const SEO = ({ location, description, meta, title, image, isHomepage }) => {
  const data = useStaticQuery(SITE_QUERY);
  const siteMetadata = data.site.siteMetadata;

  const currentURL = URLs.absolute(location.pathname);
  const titleTemplate = isHomepage ? siteMetadata.title : `%s | ${siteMetadata.title}`;
  const metaDescription = description || siteMetadata.description;
  const fullImageURL = image && URLs.absolute(image);

  const mediaMeta =
    image ? [
      { property: `og:image`,  content: fullImageURL },
      { name: `twitter:image`, content: fullImageURL },
      { name: `twitter:card`,  content: `summary_large_image` },
    ] : [
      { name: `twitter:card`,  content: `summary` },
    ];

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        { name: `description`,          content: metaDescription },
        { name: `twitter:creator`,      content: siteMetadata.social.twitter },
        { name: `twitter:title`,        content: title },
        { name: `twitter:description`,  content: metaDescription },
        { property: `og:title`,         content: title },
        { property: `og:url`,           content: currentURL },
        { property: `og:description`,   content: metaDescription },
        { property: `og:type`,          content: `website` },
      ].concat(mediaMeta).concat(meta)}
    >
      <link rel="canonical" href={currentURL} />
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: null,
  image: null,
  isHomepage: false,
};

SEO.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isHomepage: PropTypes.bool,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
