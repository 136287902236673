/**
 * URL Helpers
 */

const SITE_URL = 'https://shyr.io';

export default {
  rootPath: () => `/`,
  rootURL: () => `${SITE_URL}/`,

  absolute: (path: string) => {
    if (path[0] === '/') return `${SITE_URL}${path}`;
    return `${SITE_URL}/${path}`;
  },

  allPostsPath: () => `/blog/`,
  allTalksPath: () => `/talks/`,

  postPath: (slug: string) => `/blog/${slug}/`,
  talkPath: (slug: string) => `/talks/${slug}/`,

  youtubeEmbed:     (id: string) => `https://www.youtube.com/embed/${id}?modestbranding=1&iv_load_policy=3&rel=0`,
  speakerdeckEmbed: (id: string) => `https://speakerdeck.com/player/${id}?title=false&skipResize=true`,

};

