import React from 'react';
import Header from 'src/components/Header';
import URLs from 'src/utils/urls';

import '../styles/v3/base.sass'


const Layout = ({ location, children }) => {
  const header =
    (location.pathname === URLs.rootPath())
      ? null
      : <Header />;

  return (
    <>
      {header}
      {children}
      <footer></footer>
    </>
  );
};


export default Layout;
